import React from "react"

// Components
import Layout from "../components/layout/index"
import SEO from "../components/seo/index"
import Container from "../components/container"

export default () => {
  return (
    <Layout>
      <Container
        sm={`
                    padding-top:3rem;
                `}
      >
        <SEO title={`404: Not found`} />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Layout>
  )
}
